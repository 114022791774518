export const STATUS_ATIVO = "ATIVO";
export const STATUS_INATIVO = "INATIVO";
export const STATUS_INDEFERIDA = "INDEFERIDA";
export const STATUS_INCONSISTENTE = "INCONSISTENTE";
export const STATUS_EM_AVALIACAO = "EM AVALIAÇÃO";
export const STATUS_EMAIL_NAO_CONFIRMADO = "E-MAIL NÃO CONFIRMADO";

export const TIPO_CONSULTA_EQUIPAMENTOS = "equipamentos";
export const TIPO_CONSULTA_LAT_LONG = "latLong";

export const UFs = [
  "AC",
  "AL",
  "AP",
  "AM",
  "BA",
  "CE",
  "DF",
  "ES",
  "GO",
  "MA",
  "MT",
  "MS",
  "MG",
  "PA",
  "PB",
  "PR",
  "PE",
  "PI",
  "RJ",
  "RN",
  "RS",
  "RO",
  "RR",
  "SC",
  "SP",
  "SE",
  "TO",
];
